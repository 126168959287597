:root {
    --color-text: #fffff9;
    --color-bg: #100806;
    --color-click-to-copy-label: #444;
    --color-click-to-copy-copied: #8c8;
    --text-font: "Red Hat Display", Helvetica, Arial, sans-serif;
    --head-font: 'Aurora Magnollia Serif', 'aurora_magnollia_serif', serif;

    --scrollPosition: 0px;
    --scrollPositionText: '-0px';
    --scrollPosTop: '0px';

    --top-shadow-height: 540px;

    scroll-behavior: smooth;
  }








*           { margin:0; padding:0; outline:0; scroll-behavior: smooth !important; }
/*html        { xoverflow: hidden; } */
html, body  { width:100%; min-height:100%; scroll-behavior: smooth !important; }
img         { border:none; }

body {
    color: var(--color-text);
    background-color: var(--color-bg);
    margin: 0;  
}

body, input, textarea, select, button {
    font-size: 1.125rem;
    line-height: 1.75;
    font-weight: 300;
    letter-spacing: .025rem;
    font-family: "Red Hat Display", Helvetica, Arial, sans-serif;
}
input, textarea, select, button {
    padding: 2px 8px;
}

/* HTML5 compatibility with older browsers */
nav, section, header, footer, article { display: block; }

p {
    margin-bottom: 1.25em;
    text-indent: 1em;
}

h1, h2, h3, h4, h5, h6 { 
    text-rendering: optimizeLegibility; 
    font-family: 'Aurora Magnollia Serif', 'aurora_magnollia_serif', serif;
    font-weight: 300;
    line-height: 1.1em;
    font-style: normal;
    margin-bottom: 1em;
    letter-spacing: 2px;
    /*xtext-transform: uppercase;*/
}
/*
h1 {    font-size: 68px;    font-weight: 200;    letter-spacing: 4px; }
h2 {    font-size: 56px;    font-weight: 200;    letter-spacing: 3px; }
h3 {    font-size: 48px; }
h4 {    font-size: 32px; } 
h5 {    
    font-size: 28px;
    margin-top: 2em;
    color: #faf6be;
}
h6 {
    font-size: 1.125rem;
    line-height: 1.5em;
    letter-spacing: .025rem;
    text-transform: none;
}*/

h1 {    font-size: 62px;    font-weight: 200;    letter-spacing: 4px; }
h2 {    font-size: 50px;    font-weight: 200;    letter-spacing: 3px; }
h3 {    font-size: 42px; }
h4 {    font-size: 28px; } 
h5 {    
    font-size: 26px;
    margin-top: 2em;
    color: #faf6be;
}
h6 {
    font-size: 1.125rem;
    line-height: 1.5em;
    letter-spacing: .025rem;
    text-transform: none;
}

blockquote {
    background-color: rgba(0,0,0,.25);
    border: 1px solid rgba(255,255,255,.07);
    padding: 1em 1em;
    margin-bottom: 1em;
    border-radius: 5px;
}
blockquote p:last-child { padding-bottom: 0;margin-bottom:0; }
blockquote ol li { margin-bottom: 0.5em; }

.clearfix:before,    .clearfix:after {
    content: '\0020';
    display: block;
    overflow: hidden;
    visibility: hidden;
    width: 0;
    height: 0;
}
.clearfix:after {
    clear: both;
}
.clearfix {
    transform: scale(1);
}



/** BUTTONS **/
.content a.bttn, .content a.but, .page a.bttn, .page a.but,
a.bttn, a.but {
    text-rendering: optimizeLegibility;
    /*font-family: "Red Hat Display", Helvetica, Arial, sans-serif;*/
    font-family: var(--head-font);
    line-height: 1.2em;
    font-weight: 300;
    letter-spacing: 2px;
    color: #f0f0f0 !important;
    font-size: 1em;
    font-size: 0.85em;
    border-radius: 14px;

    transform: scale(0.975);
    margin-bottom: 4px;

    text-decoration:none;
    cursor:pointer;
    transition: all .1s ease-in-out;
    padding: 12px 17px 12px 19px;
    display: inline-block;
    text-transform: uppercase;
    white-space: nowrap;
    outline: 0 !important;
    border: 1px solid transparent !important;
    z-index:100;
    position: relative;
}
a.bttn:hover, a.but:hover {
        transform: scale(1);
        border: 1px solid #f0f0f0 !important;
        /*xbackground-color: rgba( 240, 240, 240, 0.05 ) !important;*/
        background-color: rgba( 0, 0, 0, 0.2 ) !important;
        box-shadow: inset rgba(255,255,255,.2) 0px 0px 20px -4px;
        /*xbox-shadow: inset #000 0px 1px 20px 0px; */
        position: relative;
        color: #fff !important;
}
a.bttn:active, a.but.active {
        -webkit-box-shadow:inset 0 1px 2px rgba(0,0,0,0.1);
        -moz-box-shadow:inset 0 1px 2px rgba(0,0,0,0.1);
        box-shadow:inset 0 1px 2px rgba(0,0,0,0.2);
}


a.bttn.small {
    font-size: 0.5em;
}

/*.break-before {
    clear:both;
}
.break-before::before {
    content: "x\Axx";
    white-space: pre;
}*/




/* *** */
.content code   {
    padding: 1px 3px;
    -moz-border-radius: 1px;
    -webkit-border-radius: 1px;
    -khtml-border-radius: 1px;
    border-radius: 1px;
    font-family: inherit;
    font-style: normal;
    background-color: #2440b3;
    color: #e0e0e0;
}
.content i>code, .content em>code {

    background-color: #e7c22e;
    color: black;
}
.content strong>code, .content b>code {
    background-color: #e82e54;
    color: black;
}
.content strong>em>code {
    background-color: #24b34f;
    color: black;
}

.content small {
    font-size: inherit;
    color: blue;
}


.content strong,
.page strong {
    color: #aa2d21; /*lum39*/
    color: #e9664f; /*60*/
    color: #ff9d81; /*80*/
    color: #ffab8e; /*85*/
    color: #ffb99b; /*90*/
    color: #ffc7a9; /*95*/
    font-weight: 300;
}

.content strong>em,
.page strong>em {
    font-size: 1.5em;
    font-style: normal;
    vertical-align: middle;
    margin: 0 .1em;
}


.page ul li:before {
    content: "\2726";
    color: #aa2d21;
    font-size: 1em;
    display: block;
    position: absolute;
    top: 0em;
    left: 0;
    margin-left: -1.25em;
}
.page ul li        { position: relative; list-style: none; background:none; padding-left:0; margin-bottom: 0.25em; }

.page ol { counter-reset: item }
.page ol li { display: block }
.page ol li:before { position: absolute; margin-left: -1.2em; content: counter(item) ". "; counter-increment: item; color: #aa2d21; font-weight: 300; }

.page h3 { margin-top: 1em; }
.page h4 { margin-top: 2em; }
.page h5 { margin-top: 2em; color: #faf6be; }

/* is tmoon */
.page h2    { margin-top: 2em; clear:both; }
.page p     { margin-top:0; margin-bottom:1.5em; }

.page ul, .page ol { display: inline-block; text-align: left;   margin: 0 auto 1em auto; width: 90%; margin-left: 5%; margin-right: 5%;}
.page ul li, .page ol li { line-height: 1.5em; }

#vedlys .page > article:first-of-type ul { width: auto; }

/*dl.toggle dt { padding-left: 1.5em; background: url("../img/arrows3.png") no-repeat 0 -36px; text-decoration:none; font-weight: 700; }
dl.toggle dd { margin-bottom: 2em;    padding-left: 1.5em; }
dl.toggle dt.active { background-position: 0 5px; }
dl.toggle dd strong { font-weight: bold; font-style: italic; color: #fff; }*/

.page dl dt { margin-bottom: 0.5em; margin-top: 0.5em; color: #f0e7b9; }
.page dl dd { margin-bottom: 1em; }

.page small { font-size: inherit; color: #c0c0c0; }

p.center { text-align: center; }


#content li a, #content p a, .content a, .page a
{
    text-decoration: none;
    /*display:inline-block;*/
    /*font-weight: 300;*/
    /*border-radius: 2px;*/
    transition: all .05s ease-in-out;

}
#content li a:link, 
#content li a:visited 
#content p a:link, 
#content p a:visited,
.content a:link, .content a:visited,
.page a:link, .page a:visited
{
    color: rgb(244, 132, 113);
    border-bottom: 1px solid rgba(244, 132, 113, .2);
}
#content li a:hover, 
#content li a:active,
#content li a:focus 
#content p a:hover, 
#content p a:active,
#content p a:focus,
.content a:hover, .content a:active, .content a:focus,
.page a:hover, .page a:active, .page a:focus
{
    border-bottom: 1px solid rgba(244, 132, 113, 1);
    color: #f48471;
}



hr, .next-link {
    display: block;
    clear: both;
    margin-top: 72px;
    width: 28px;
    height: 28px;
    margin-left: auto;
    margin-right: auto;
    background-size: 100% 100%;
    border: 0 !important;
    padding: 0;
    line-height: 1;
    transition: all .1s ease-in-out;
    text-decoration: none;
}
a.next-link:after {
    content: "\2726";
    color: #aa2d21;
    font-size: 32px;
    position: relative;
}
a.next-link:hover, a.next-link:active, a.next-link:focus {
    padding: 0;
    border: 0 !important;
    /*-webkit-animation-iteration-count: 1; /* Safari 4.0 - 8.0 */
    /*animation-iteration-count: 1;*/
    text-decoration: none; 
    opacity:1;
    transform: scale(1.1) rotate(-180deg) translateY(-4px);
}

/*.next-link.btm {
    position: absolute;
    margin-top: 0;
    bottom: 7vh;
    left: 50%;
    margin-left: -14px;
}

.intro a.next-link {
  opacity: 0.8;
  animation: pulse2 3s 2s alternate 9;
  border-radius: 50%;
}


@keyframes pulse2 {
    0%,100% { opacity: 0.8; box-shadow: 0 0 0px rgba(255,255,255,.0); background-color: rgba(255,255,255,0);}
    50% { opacity: 1; box-shadow: 0 0 24px rgba(255,255,255,.4); background-color: rgba(255,255,255,.15);}
}
*/


/*article .post_title {
    position: sticky;
    top: 120px;
}*/

.round { border-radius: 50%; }
.rc1 { border-radius: 1em; }