:root {
  --color-text: #fffff9;
  --color-bg: #100806;
  --color-click-to-copy-label: #444;
  --color-click-to-copy-copied: #8c8;
  --text-font: "Red Hat Display", Helvetica, Arial, sans-serif;
  --head-font: "Aurora Magnollia Serif", "aurora_magnollia_serif", serif;
  --scrollPosition: 0px;
  --scrollPositionText: "-0px";
  --scrollPosTop: "0px";
  --top-shadow-height: 540px;
  scroll-behavior: smooth;
}

* {
  outline: 0;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth !important;
}

html, body {
  width: 100%;
  min-height: 100%;
  scroll-behavior: smooth !important;
}

img {
  border: none;
}

body {
  color: var(--color-text);
  background-color: var(--color-bg);
  margin: 0;
}

body, input, textarea, select, button {
  letter-spacing: .025rem;
  font-family: Red Hat Display, Helvetica, Arial, sans-serif;
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 1.75;
}

input, textarea, select, button {
  padding: 2px 8px;
}

nav, section, header, footer, article {
  display: block;
}

p {
  text-indent: 1em;
  margin-bottom: 1.25em;
}

h1, h2, h3, h4, h5, h6 {
  text-rendering: optimizelegibility;
  letter-spacing: 2px;
  margin-bottom: 1em;
  font-family: Aurora Magnollia Serif, aurora_magnollia_serif, serif;
  font-style: normal;
  font-weight: 300;
  line-height: 1.1em;
}

h1 {
  letter-spacing: 4px;
  font-size: 62px;
  font-weight: 200;
}

h2 {
  letter-spacing: 3px;
  font-size: 50px;
  font-weight: 200;
}

h3 {
  font-size: 42px;
}

h4 {
  font-size: 28px;
}

h5 {
  color: #faf6be;
  margin-top: 2em;
  font-size: 26px;
}

h6 {
  letter-spacing: .025rem;
  text-transform: none;
  font-size: 1.125rem;
  line-height: 1.5em;
}

blockquote {
  background-color: #00000040;
  border: 1px solid #ffffff12;
  border-radius: 5px;
  margin-bottom: 1em;
  padding: 1em;
}

blockquote p:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

blockquote ol li {
  margin-bottom: .5em;
}

.clearfix:before, .clearfix:after {
  content: " ";
  visibility: hidden;
  width: 0;
  height: 0;
  display: block;
  overflow: hidden;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  transform: scale(1);
}

.content a.bttn, .content a.but, .page a.bttn, .page a.but, a.bttn, a.but {
  text-rendering: optimizelegibility;
  font-family: var(--head-font);
  letter-spacing: 2px;
  cursor: pointer;
  text-transform: uppercase;
  white-space: nowrap;
  z-index: 100;
  border-radius: 14px;
  margin-bottom: 4px;
  padding: 12px 17px 12px 19px;
  font-size: .85em;
  font-weight: 300;
  line-height: 1.2em;
  text-decoration: none;
  transition: all .1s ease-in-out;
  display: inline-block;
  position: relative;
  transform: scale(.975);
  color: #f0f0f0 !important;
  border: 1px solid #0000 !important;
  outline: 0 !important;
}

a.bttn:hover, a.but:hover {
  position: relative;
  transform: scale(1);
  box-shadow: inset 0 0 20px -4px #fff3;
  color: #fff !important;
  background-color: #0003 !important;
  border: 1px solid #f0f0f0 !important;
}

a.bttn:active, a.but.active {
  -webkit-box-shadow: inset 0 1px 2px #0000001a;
  -moz-box-shadow: inset 0 1px 2px #0000001a;
  box-shadow: inset 0 1px 2px #0003;
}

a.bttn.small {
  font-size: .5em;
}

.content code {
  -khtml-border-radius: 1px;
  color: #e0e0e0;
  background-color: #2440b3;
  border-radius: 1px;
  padding: 1px 3px;
  font-family: inherit;
  font-style: normal;
}

.content i > code, .content em > code {
  color: #000;
  background-color: #e7c22e;
}

.content strong > code, .content b > code {
  color: #000;
  background-color: #e82e54;
}

.content strong > em > code {
  color: #000;
  background-color: #24b34f;
}

.content small {
  font-size: inherit;
  color: #00f;
}

.content strong, .page strong {
  color: #ffc7a9;
  font-weight: 300;
}

.content strong > em, .page strong > em {
  vertical-align: middle;
  margin: 0 .1em;
  font-size: 1.5em;
  font-style: normal;
}

.page ul li:before {
  content: "✦";
  color: #aa2d21;
  margin-left: -1.25em;
  font-size: 1em;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.page ul li {
  background: none;
  margin-bottom: .25em;
  padding-left: 0;
  list-style: none;
  position: relative;
}

.page ol {
  counter-reset: item;
}

.page ol li {
  display: block;
}

.page ol li:before {
  content: counter(item) ". ";
  counter-increment: item;
  color: #aa2d21;
  margin-left: -1.2em;
  font-weight: 300;
  position: absolute;
}

.page h3 {
  margin-top: 1em;
}

.page h4 {
  margin-top: 2em;
}

.page h5 {
  color: #faf6be;
  margin-top: 2em;
}

.page h2 {
  clear: both;
  margin-top: 2em;
}

.page p {
  margin-top: 0;
  margin-bottom: 1.5em;
}

.page ul, .page ol {
  text-align: left;
  width: 90%;
  margin: 0 5% 1em;
  display: inline-block;
}

.page ul li, .page ol li {
  line-height: 1.5em;
}

#vedlys .page > article:first-of-type ul {
  width: auto;
}

.page dl dt {
  color: #f0e7b9;
  margin-top: .5em;
  margin-bottom: .5em;
}

.page dl dd {
  margin-bottom: 1em;
}

.page small {
  font-size: inherit;
  color: silver;
}

p.center {
  text-align: center;
}

#content li a, #content p a, .content a, .page a {
  text-decoration: none;
  transition: all 50ms ease-in-out;
}

#content li a:link, #content li a:visited #content p a:link, #content p a:visited, .content a:link, .content a:visited, .page a:link, .page a:visited {
  color: #f48471;
  border-bottom: 1px solid #f4847133;
}

#content li a:hover, #content li a:active, #content li a:focus #content p a:hover, #content p a:active, #content p a:focus, .content a:hover, .content a:active, .content a:focus, .page a:hover, .page a:active, .page a:focus {
  color: #f48471;
  border-bottom: 1px solid #f48471;
}

hr, .next-link {
  clear: both;
  background-size: 100% 100%;
  width: 28px;
  height: 28px;
  margin-top: 72px;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  line-height: 1;
  text-decoration: none;
  transition: all .1s ease-in-out;
  display: block;
  border: 0 !important;
}

a.next-link:after {
  content: "✦";
  color: #aa2d21;
  font-size: 32px;
  position: relative;
}

a.next-link:hover, a.next-link:active, a.next-link:focus {
  opacity: 1;
  padding: 0;
  text-decoration: none;
  transform: scale(1.1)rotate(-180deg)translateY(-4px);
  border: 0 !important;
}

.round {
  border-radius: 50%;
}

.rc1 {
  border-radius: 1em;
}
/*# sourceMappingURL=landing.9c7bb9d0.css.map */
